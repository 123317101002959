import React from "react";
import { ContainerProps } from "../helpers/types";

const Container = ({ className, children, ...props }: ContainerProps) => {
  return (
    <div
      className={`md:max-w-[1580px] w-full mx-auto px-4 ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};
export default Container;
